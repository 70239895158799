<template>
  <div>
    <Header
      :totalAmount="thousandSeparator(outsideData.totalAmount)"
      :gross-payment="thousandSeparator(Math.abs(data.monthlyRepayment))"
      :customer-rate-of-interest="
        formatPercentage(this.outsideData.yearlyInterestRate)
      "
    />
    <div class="calculation">
      <div class="calculation__body">
        <div class="calculation__sliders">
          <h2 class="calculation__header">Tilpas din finansiering</h2>

          <Slider
            v-model="downPayment"
            title="Udbetaling"
            :options="downPaymentOptions"
            :dragEndCallback="doCalculate"
          />

          <Slider
            v-model="terms"
            title="Løbetid"
            :options="termsOptions"
            :dragEndCallback="doCalculate"
          />

          <p v-if="showError" ref="error" class="calculation__error">
            Den sammensatte udbetaling og løbetid er ikke mulig
          </p>
        </div>

        <div class="calculation__overview">
          <div class="calculation__text-container">
            <p class="calculation__text">Saml. kreditbeløb</p>
            <p class="calculation__text">
              {{ thousandSeparator(data.totalCreditAmount) }} kr.
            </p>
          </div>
          <div class="calculation__text-container">
            <p class="calculation__text">Saml. kreditomk.</p>
            <p class="calculation__text">
              {{ thousandSeparator(data.totalCost) }} kr
            </p>
          </div>
          <div class="calculation__text-container">
            <p class="calculation__text">Variabel rente</p>
            <p class="calculation__text">
              {{ formatPercentage(this.outsideData.yearlyInterestRate) }}%
            </p>
          </div>
          <div class="calculation__text-container">
            <p class="calculation__text">Saml. tilbagebetaling</p>
            <p class="calculation__text">
              {{ thousandSeparator(Math.abs(data.totalRefund)) }} kr.
            </p>
          </div>
          <div class="calculation__text-container">
            <p class="calculation__text">ÅOP</p>
            <p class="calculation__text">
              {{ formatPercentage(Math.abs(data.annualCostInPercent)) }}%
            </p>
          </div>
        </div>
      </div>

      <p v-html="outsideData.dutyText" class="calculation__terms"></p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { thousandSeparator } from "@/functions";

import Header from "./Header.vue";
import Slider from "./Slider.vue";

export default {
  components: {
    Header,
    Slider,
  },
  data() {
    return {
      downPayment: this.downPaymentOptions.value,
      terms: this.termsOptions.value,
      data: {},
      showError: false,
    };
  },
  props: {
    termsOptions: undefined,
    downPaymentOptions: undefined,
    isLoadingUpdate: undefined,
    outsideData: undefined,
  },
  methods: {
    doCalculate() {
      this.showErrorHappend(false);
      this.isLoadingUpdate(true);
      axios
        .post(`/AnnualPercentageRateCost`, {
          DownPayment: this.downPayment,
          RunningTimeInMonths: this.terms,
          YearlyInterestRate: this.outsideData.yearlyInterestRate,
          TotalFee: this.outsideData.totalFee,
          TotalAmount: this.outsideData.totalAmount,
        })
        .then((response) => {
          this.isLoadingUpdate(false);

          if (response.data === "") {
            this.showErrorHappend(true);
            return;
          }

          if (Math.abs(response.data.annualCostInPercent) > 25) {
            this.showErrorHappend(true);
          }

          // exception  happend, most likely "Exception: Cannot calculate rate using the arguments provided."
          if (response.data.statusCode === 100) {
            this.showErrorHappend(true);
          }

          this.data = response.data;
        });
    },
    showErrorHappend(value) {
      this.showError = value;
    },
    thousandSeparator(value) {
      return thousandSeparator(value);
    },
    formatPercentage(value) {
      if (value === undefined) return undefined;

      return value.toString().replace(".", ",");
    },
  },
  created() {
    this.doCalculate();
  },
};
</script>

<style lang="scss" scoped>
.calculation {
  @apply p-6 border border-gray-400 border-t-0;

  &__header {
    @apply mt-4 mb-8 text-2xl;
  }

  &__overview {
    @apply bg-gray-200 p-4;
  }

  &__text-container {
    @apply flex justify-between;
  }

  &__text {
    @apply text-sm leading-7 text-gray-600;
  }

  &__terms {
    @apply mt-4 text-xs text-gray-500;
  }

  &__error {
    @apply bg-red-600 py-4 mt-2 mb-3 text-white;
  }

  @screen lg {
    &__header {
      @apply text-left;
    }

    &__body {
      @apply flex;
    }

    &__sliders {
      @apply w-2/3 pr-6;
    }

    &__overview {
      @apply w-1/3;
    }
  }
}
</style>
