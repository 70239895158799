<template>
  <div class="header">
    <div class="header__item header__item--first">
      <p class="header__item-title">Bilens pris (kr.)</p>
      <strong class="header__item-value">{{ totalAmount }}</strong>
    </div>
    <div class="header__item">
      <p class="header__item-title">Variabel rente</p>
      <strong class="header__item-value">{{ customerRateOfInterest }}%</strong>
    </div>
    <div class="header__item header__item--last">
      <p class="header__item-title">Mdl. ydelse (kr.)</p>
      <strong class="header__item-value">{{ grossPayment }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    grossPayment: undefined,
    customerRateOfInterest: undefined,
    totalAmount: undefined,
  },
};
</script>

/* The reason we use a class for first/last child is it is easier to overwrite
without having to face a higher specificity with :first/last-child */
<style lang="scss" scoped>
.header {
  @apply flex flex-wrap bg-gray-500;

  &__item {
    @apply w-1/2 p-5 text-white;

    &--first {
      @apply w-full bg-gray-400;
    }
  }

  &__item-title {
    @apply tracking-widest;
  }

  &__item-value {
    @apply text-2xl tracking-widest;
  }

  @screen lg {
    &__item {
      @apply w-1/3 pt-6 pb-8 bg-gray-400 text-left;

      &--first,
      &--last {
        @apply bg-gray-500;
      }
    }

    &__item-value {
      @apply text-4xl leading-relaxed;
    }
  }
}
</style>
