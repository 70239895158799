<template>
  <div class="entry" v-if="isVisible">
    <img :src="logoUrl" class="entry__image" />
    <Calculate
      :terms-options="termsOptions"
      :down-payment-options="downPaymentOptions"
      :outside-data="{
        totalAmount,
        yearlyInterestRate,
        totalFee,
        dutyText,
      }"
      :is-loading-update="isLoadingUpdate"
    />
    <div v-if="isLoading" class="entry__loader">
      <Loader />
    </div>
  </div>
</template>

<script>
import Calculate from "./Calculate.vue";
import Loader from "./Loader.vue";

export default {
  components: {
    Calculate,
    Loader,
  },
  data() {
    return {
      termsOptions: undefined,
      downPaymentOptions: undefined,
      isLoading: false,
      isVisible: true,
    };
  },
  props: {
    totalAmount: { default: undefined, type: Number },
    totalFee: { default: undefined, type: Number },
    yearlyInterestRate: { default: undefined, type: Number },
    logoUrl: { default: undefined, type: String },
    dutyText: { default: undefined, type: String },
  },
  methods: {
    getTermsOptions() {
      let max = this.getMaxTerms();

      return {
        value: max,
        min: 12,
        max: max,
        interval: 1,
        postfix: "måneder",
      };
    },
    getDownPaymentOptions() {
      const max = this.sanitizeDownPayment(this.totalAmount * 0.8);
      const min = this.sanitizeDownPayment(this.totalAmount * 0.2);
      const startValue = this.sanitizeDownPayment(this.totalAmount * 0.201);

      return {
        value: startValue,
        min: min,
        max: max,
        interval: 1000,
        postfix: "kr.",
      };
    },
    sanitizeDownPayment(value) {
      return Math.ceil(value / 1000) * 1000;
    },
    getMaxTerms() {
      return 96;
    },
    isLoadingUpdate(value) {
      this.isLoading = value;
    },
  },
  created() {
    this.termsOptions = this.getTermsOptions();
    this.downPaymentOptions = this.getDownPaymentOptions();
  },
};
</script>

<style lang="scss" scoped>
.entry {
  @apply text-center bg-white relative p-3;

  &__image {
    @apply mx-auto w-64 mt-4 mb-7;
  }

  &__loader {
    @apply absolute inset-0 bg-gray-50 opacity-75 z-10 flex justify-center items-center;
  }
}
</style>
